import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';

import { useBusinessBankStore } from '@/stores/business-bank.store';

export default defineNuxtRouteMiddleware(async () => {
  const businessBankStore = useBusinessBankStore();
  const { businessInfos } = storeToRefs(businessBankStore);
  const { fetchBusinessInfos } = businessBankStore;

  if (!businessInfos.value) {
    await fetchBusinessInfos();
  }

  return true;
});
